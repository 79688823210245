/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import PropTypes from "prop-types";
import RoofTopIcon from "../images/icons/roof-top.svg";
import SkewedText from "../components/SkewedText";

const AccordionList = ({
  ariaLabelText = "Accordion Control Group Buttons",
  bannerText,
  children,
  className,
  ...props
}) => {
  return (
    <Box
      {...props}
      className={className}
      sx={{
        maxWidth: [null, null, "1200px"],
        mx: [null, null, "auto"],
        px: [null, null, 3],
      }}
    >
      <Box sx={{ position: "relative" }}>
        <RoofTopIcon
          sx={{
            height: "auto",
            verticalAlign: "middle",
            width: "100%",
            mb: "-2px",
          }}
        />

        <SkewedText
          as="h1"
          text={bannerText}
          sx={{
            bottom: 0,
            left: [-2, null, -20],
            position: "absolute",
            zIndex: 7,
          }}
        />
      </Box>
      <ul
        aria-label={ariaLabelText}
        sx={{
          backgroundColor: "blue",
          listStyleType: "none",
          m: 0,
          p: 0,
        }}
      >
        {children}
      </ul>
    </Box>
  );
};

AccordionList.propTypes = {
  ariaLabelText: PropTypes.string,
  bannerText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

AccordionList.defaultProps = {
  ariaLabelText: "Accordion Control Group Buttons",
};

export default AccordionList;

/** @jsx jsx */
import PropTypes from "prop-types";
import { Box, Flex, jsx } from "theme-ui";
import BackgroundDots from "../images/icons/background-dots-1.svg";
import SkewedText from "./SkewedText";

const Staggered = ({
  bannerTag,
  bannerText,
  className,
  firstBlockChildren,
  reverse,
  secondBlockChildren,
  ...props
}) => {
  const firstBlockStyles = reverse
    ? {
        pl: "calc(100% * 203/1200)",
        pr: "calc(100% * 17/200)",
      }
    : {
        pl: [4, null, "calc(100% * 17/200)"],
        pr: [4, null, "calc(100% * 230/1200)"],
      };
  const secondBlockStyles = reverse
    ? {
        mr: "calc(-100% * 61/400)",
      }
    : {
        ml: [null, null, "calc(-100% * 61/400)"],
      };

  return (
    <Flex
      {...props}
      className={className}
      sx={{
        flexDirection: reverse ? "row-reverse" : "row",
        position: "relative",
        flexWrap: ["wrap", null, "nowrap"],
        mb: ["calc(100% * 7/138)", null, 0],
      }}
    >
      <Box
        sx={{
          backgroundColor: "blue",
          color: "textWhite",
          flex: ["0 0 100%", null, "0 0 calc(100% * 59/120)"],
          mb: [null, null, "calc(100% * 187/1200)"],
          position: "relative",
          pt: [10, 11, "calc(100% * 11/75)"],
          textAlign: ["center", null, "left"],
          zIndex: 2,
          pb: 5,
          ...firstBlockStyles,
        }}
      >
        {firstBlockChildren}

        <SkewedText
          as={bannerTag}
          text={bannerText}
          sx={{
            left: [-20, "-2.5%", "-3.5%"],
            position: "absolute",
            top: [5, null, 50],
            width: [null, null, "106%"],
          }}
        />
      </Box>

      <Box
        sx={{
          flex: ["0 0 100%", null, "0 0 calc(100% * 793/1200)"],
          mt: [null, null, "calc(100% * 11/75)"],
          position: "relative",
          zIndex: 3,
          ...secondBlockStyles,
        }}
      >
        {secondBlockChildren}
      </Box>

      <BackgroundDots
        sx={{
          bottom: ["calc(-100% * 20/471)"],
          height: ["auto", null, "auto"],
          left: [null, null, "50%"],
          position: "absolute",
          right: ["calc(-100% * 5/138)"],
          top: [null, null, "40%"],
          transform: ["rotate(90deg)", null, "translate(-50%, -50%)"],
          verticalAlign: "middle",
          width: ["50%", null, "31%"],
          zIndex: 1,
        }}
      />
    </Flex>
  );
};

Staggered.propTypes = {
  bannerTag: PropTypes.string,
  bannerText: PropTypes.string,
  className: PropTypes.string,
  firstBlockChildren: PropTypes.node,
  reverse: PropTypes.bool,
  secondBlockChildren: PropTypes.node,
};

Staggered.defaultProps = {
  bannerTag: "h1",
  reverse: false,
};

export default Staggered;

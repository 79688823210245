/** @jsx jsx */
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Box, Container, Flex, Heading, jsx } from "theme-ui";
import Accordion from "../components/Accordion";
import AccordionList from "../components/AccordionList";
import CertificationList from "../components/CertificationList";
import HexagonCard from "../components/HexagonCard";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Staggered from "../components/Staggered";
import CustomerIcon from "../images/icons/customer-icon.svg";
import KnowledgeableIcon from "../images/icons/knowledgeable-icon.svg";
import PricingIcon from "../images/icons/pricing-icon.svg";
import RepairIcon from "../images/icons/repair-icon.svg";
import SchedulingIcon from "../images/icons/scheduling-icon.svg";

const ACCORDION_CONTENT = [
  {
    name: "Knowledgeable Service",
    content: (
      <Flex sx={{ alignItems: ["flex-start", null, "center"] }}>
        <KnowledgeableIcon
          sx={{
            verticalAlign: "middle",
            flex: ["0 0 20%"],
          }}
        />

        <p sx={{ flex: "0 0 80%", pl: 6 }}>
          In our 35+ years experience in the roofing industry, we’ve worked on
          every kind of roof under the Arizona sun. Our service technicians are
          experienced journeymen with the skills to make a roof last as long as
          possible. We know what it takes to keep yours leak and damage-free,
          and we’ll leave you well-informed about how to protect your home’s
          most important investment.
        </p>
      </Flex>
    ),
  },
  {
    name: "ON-Time SCHEDULing",
    content: (
      <Flex sx={{ alignItems: ["flex-start", null, "center"] }}>
        <SchedulingIcon
          sx={{
            verticalAlign: "middle",
            flex: ["0 0 20%"],
          }}
        />

        <p sx={{ flex: "0 0 80%", pl: 6 }}>
          Responding quickly to your requests is our top priority. Because we
          focus on repairs, we can offer faster, more reliable services than
          other roofing companies. After you reach out to us, we’ll respond
          within 24-48 hours and send our contractors to diagnose your roof’s
          exact problem. Whenever the issue can be fixed within a few hours,
          they will address it on the same day. If the damage on your roof
          requires more extensive repairs, we will schedule a timely repair on a
          date that works well for you.
        </p>
      </Flex>
    ),
  },
  {
    name: "Transparent Pricing",
    content: (
      <Flex sx={{ alignItems: ["flex-start", null, "center"] }}>
        <PricingIcon
          sx={{
            verticalAlign: "middle",
            flex: ["0 0 20%"],
          }}
        />

        <div sx={{ flex: "0 0 80%", pl: 4 }}>
          <p>
            Our customers can expect a professional, cost-effective experience
            with our high standards and attention to detail. Whenever possible,
            we charge flat fees for our services so you know exactly what you’ll
            need to spend on your roof. We charge an up-front estimate fee of
            $125 for all house calls. When you choose us to complete your repair
            or maintenance, we will absorb this fee into your repair costs.
          </p>

          <p sx={{ mb: 0 }}>Repair Costs depend on:</p>
          <ul sx={{ listStyleType: "disc", my: 0 }}>
            <li>The current state of your roof</li>
            <li>The extent of the damage to your roofing system</li>
            <li>What type of roof you have and the roofing materials used</li>
            <li>The age of your roof and its life expectancy</li>
          </ul>
        </div>
      </Flex>
    ),
  },
  {
    name: "Guaranteed Repair Satisfaction",
    content: (
      <Flex sx={{ alignItems: ["flex-start", null, "center"] }}>
        <RepairIcon
          sx={{
            verticalAlign: "middle",
            flex: ["0 0 20%"],
          }}
        />

        <p sx={{ flex: "0 0 80%", pl: 6 }}>
          We take great pride in our work and your satisfaction. Per Arizona
          Registrar of Contractors (ROC) guidelines, our repairs come with a two
          year warranty that covers the exact area of the repair, properly
          documented with photos. Any faulty workmanship within the warranty
          period will be closely inspected to determine responsibility.
        </p>
      </Flex>
    ),
  },
  {
    name: "Quality Customer Service",
    content: (
      <Flex sx={{ alignItems: ["flex-start", null, "center"] }}>
        <CustomerIcon
          sx={{
            verticalAlign: "middle",
            flex: ["0 0 20%"],
          }}
        />

        <p sx={{ flex: "0 0 80%", pl: 6 }}>
          When you choose Legacy Repair, you can expect timely, professional and
          transparent service. We will inform you about your roof’s needs,
          quickly repair its issues, and make sure you have a clear
          understanding of your costs and our billing practices. We’re not happy
          unless you are fully satisfied with our service and workmanship.
        </p>
      </Flex>
    ),
  },
];

const FIRST_BLOCK_CONTENT = (
  <p>
    We have been trusted roofers throughout northern and central Arizona for
    over 35 years. Legacy Repair employees are professional journeyman roofers.
    We employ trustworthy workers and ensure our employees are thoroughly vetted
    during the hiring process.
  </p>
);

const IndexPage = ({ data }) => {
  const VideoImage = getImage(data.VideoPoster.childImageSharp.gatsbyImageData);

  // console.log(VideoImage);

  // const SECOND_BLOCK_CONTENT = <Video poster={data.VideoPoster.publicURL} />;
  const SECOND_BLOCK_CONTENT = (
    <GatsbyImage image={VideoImage} alt="Men reviewing plans" />
  );

  const HEXAGONCARD_LIST = [
    {
      imageAlt: "basic house",
      imageURL: data.HexagonHouse.publicURL,
      linkURL: "/residential",
      headingText: "Residential",
      content: (
        <p>
          Save your home a roof replacement by repairing problems like leaks,
          cracking, and deterioration before they become disasters.
        </p>
      ),
    },
    {
      imageAlt: "commercial buildings",
      imageURL: data.HexagonBuilding.publicURL,
      linkURL: "/commercial",
      headingText: "Commercial",
      content: (
        <p>
          Count on us as your reliable, consistent partner for commercial roof
          repair and maintenance. You will be prioritized in emergencies and
          receive prompt, timely repairs for roof leaks and other issues.
        </p>
      ),
    },
    {
      imageAlt: "man holding a hammer",
      imageURL: data.HexagonConstructionWorker.publicURL,
      linkURL: "/roof-maintenance",
      headingText: "Roof maintenance",
      content: (
        <p>
          Prevention is the key to making your roof last longer. We offer
          cleaning, recoating, and general inspection services in addition to
          repairs.
        </p>
      ),
    },
  ];

  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerFancyBanner={true}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Homepage" />

      <Box
        as="section"
        sx={{
          pt: [8, null, 12],
          mb: [8, null, 11],
          backgroundImage: [
            `url(${data.TopDotsMobile.publicURL})`,
            null,
            `url(${data.TopDots.publicURL})`,
          ],
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          backgroundSize: "100%",
        }}
      >
        <Container>
          <div sx={{ textAlign: "center", mb: [8, null, 10] }}>
            <Heading as="h1" variant="styles.fancyUnderline">
              Roof Repair Services
            </Heading>
          </div>

          <ul
            sx={{
              display: "grid",
              gridTemplateColumns: ["1, 100%", null, "repeat(3, 31%)"],
              justifyContent: "space-between",
              listStyleType: "none",
              m: 0,
              p: 0,
              rowGap: 9,
            }}
          >
            {HEXAGONCARD_LIST.map((item, index) => (
              <li key={index}>
                <HexagonCard
                  content={item.content}
                  headingText={item.headingText}
                  imageAlt={item.imageAlt}
                  imageURL={item.imageURL}
                  linkURL={item.linkURL}
                />
              </li>
            ))}
          </ul>
        </Container>
      </Box>

      <AccordionList
        as="section"
        ariaLabelText="What were made of accordion control group buttons."
        bannerText="What We’re made of."
        sx={{ my: [8, null, 11] }}
      >
        {ACCORDION_CONTENT.map((item, index) => (
          <li key={item.name}>
            <Accordion
              id={`accordion-${index}`}
              separatorEnabled={
                index === ACCORDION_CONTENT.length - 1 ? false : true
              }
              title={item.name}
            >
              {item.content}
            </Accordion>
          </li>
        ))}
      </AccordionList>

      <Box
        as="section"
        backgroundColor="#EAEAEA"
        sx={{
          py: [8, null, 11],
        }}
      >
        <Container sx={{ overflow: "hidden" }}>
          <Staggered
            bannerText="Why choose legacy?"
            firstBlockChildren={FIRST_BLOCK_CONTENT}
            secondBlockChildren={SECOND_BLOCK_CONTENT}
          />
        </Container>
      </Box>

      <CertificationList
        backgroundImageDesktop={data.BottomDots}
        backgroundImageMobile={data.BottomDotsMobile}
      />

      {/* <Box
        as="section"
        backgroundColor="blue"
        sx={{
          overflow: "hidden",
          py: [8, null, 11],
        }}
      >
        <Container>
          <Heading
            as="h1"
            variant="styles.h1"
            sx={{
              color: "textWhite",
              fontWeight: "body",
              mb: 4,
              textAlign: "center",
            }}
          >
            Customer Testimonials
          </Heading>
          <Carousel slides={Slides} arrowColor="#fff" />
        </Container>

         <p
          sx={{
            color: "textWhite",
            fontSize: [1, null, 3],
            textAlign: "center",
          }}
        >
          Leave feedback{" "}
          <a
            href="#"
            sx={{
              color: "primary",
              display: "inline-block",
              textDecoration: "none",
            }}
          >
            here
          </a>
        </p> 
      </Box> */}
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "homepage-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    VideoPoster: file(relativePath: { eq: "video-poster.jpg" }) {
      id
      publicURL
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    HexagonHouse: file(relativePath: { eq: "hexagon-house.svg" }) {
      id
      publicURL
    }
    HexagonBuilding: file(relativePath: { eq: "hexagon-building.svg" }) {
      id
      publicURL
    }
    HexagonConstructionWorker: file(
      relativePath: { eq: "hexagon-construction-worker.svg" }
    ) {
      id
      publicURL
    }
    BottomDots: file(relativePath: { eq: "bottom-dots.svg" }) {
      id
      publicURL
    }
    BottomDotsMobile: file(relativePath: { eq: "bottom-dots-mobile.svg" }) {
      id
      publicURL
    }
    TopDots: file(relativePath: { eq: "top-dots.svg" }) {
      id
      publicURL
    }
    TopDotsMobile: file(relativePath: { eq: "top-dots-mobile.svg" }) {
      id
      publicURL
    }
  }
`;

export default IndexPage;

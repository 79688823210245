/** @jsx jsx */
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { Box, jsx } from "theme-ui";

const Accordion = ({
  children,
  className,
  separatorEnabled = true,
  title,
  id = "accordion",
}) => {
  const [accordionActive, setAccordionActive] = useState(false);
  const [contentHeight, setContentHeight] = useState("0px");
  const content = useRef(null);

  const toggleAccordion = () => {
    setAccordionActive(!accordionActive);
    accordionActive
      ? setContentHeight("0px")
      : setContentHeight(content.current.scrollHeight + "px");
  };

  const activeButtonStyles = accordionActive
    ? { boxShadow: "inset 0px 15px 35px rgba(0, 0, 0, 0.25)" }
    : {};

  const separatorStyles = separatorEnabled
    ? {
        backgroundColor: "primary",
        borderRadius: "50%",
        content: '""',
        height: "4px",
        left: "50%",
        position: "absolute",
        top: "calc(100% - 3px)",
        transform: "translateX(-50%)",
        maxWidth: "319px",
        width: "80%",
        zIndex: 6,
      }
    : {};

  return (
    <Box
      className={className}
      sx={{
        position: "relative",
        "&::after": {
          ...separatorStyles,
        },
      }}
    >
      <button
        type="button"
        aria-controls={id}
        aria-expanded={accordionActive}
        id={`button-${id}`}
        onClick={toggleAccordion}
        sx={{
          backgroundColor: "blue",
          border: "none",
          color: "textWhite",
          cursor: "pointer",
          fontSize: [2, null, 5],
          fontWeight: "bold",
          listStyle: "none",
          m: 0,
          position: "relative",
          px: [7, null, 0],
          py: [5, null, 6],
          textAlign: "center",
          textTransform: "uppercase",
          transition: "all 0.3s",
          width: "100%",
          zIndex: 6,
          "&:focus:not(:focus-visible)": {
            outline: "none",
          },
          ...activeButtonStyles,
        }}
      >
        {title}
        <span
          sx={{
            display: "block",
            height: [19, null, 24],
            position: "absolute",
            right: ["3%", null, "5%"],
            top: "50%",
            transform: "translateY(-50%)",
            width: [19, null, 24],
          }}
        >
          <span
            className="plus-sign"
            sx={{
              backgroundColor: "textWhite",
              display: "block",
              height: [19, null, 24],
              position: "relative",
              transform: accordionActive ? "rotate(135deg)" : "",
              transition: "all 0.3s",
              width: [2, null, 3],
              "&::before": {
                backgroundColor: "textWhite",
                content: '""',
                display: "block",
                height: [19, null, 24],
                left: 0,
                position: "absolute",
                top: 0,
                transform: "rotate(90deg)",
                width: [2, null, 3],
              },
            }}
          ></span>
        </span>
      </button>

      <Box
        ref={content}
        id={id}
        role="region"
        aria-hidden={!accordionActive}
        aria-labelledby={`button-${id}`}
        sx={{
          backgroundColor: "textWhite",
          boxShadow: "inset 0px -15px 35px rgba(0, 0, 0, 0.25)",
          height: contentHeight,
          overflow: "hidden",
          transition: "all 0.3s",
        }}
      >
        <Box
          sx={{
            maxWidth: [null, null, "55%"],
            mx: [null, null, "auto"],
            px: [4, null, 0],
            py: [5, null, 8],
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

Accordion.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  separatorEnabled: PropTypes.bool,
  title: PropTypes.string,
};

Accordion.defaultProps = {
  id: "accordion",
  separatorEnabled: true,
};

export default Accordion;
